<template>
  <div>
    <slot />
  </div>
</template>

<style scoped>
html {
  @apply font-sans text-base antialiased text-gray-700;
}

a {
  @apply text-gray-900 underline;
}

a:hover,
a:focus {
  @apply text-gray-400;
}
</style>
